import React from 'react'
import styled from '@emotion/styled'
import AccountCircle from '@mui/icons-material/AccountCircle'
import IconButton from '@mui/material/Icon'
import Tooltip from '@mui/material/Tooltip'
import { getFerretImageUrl } from '../../utils/images'

interface UserProfileAvatarProps {
  avatarUrl: string
  onClick: (event: React.MouseEvent<HTMLElement>) => void
}

const StyledAccountCircle = styled.div`
  margin-top: 7px;
  color: #d58915;
  svg {
    font-size: 36px;
  }
`

const getAvatarUrl = (avatarUrl: string): string => {
  return avatarUrl ?? getFerretImageUrl('Rummy.png')
}

const UserProfileAvatar = ({ avatarUrl, onClick }: UserProfileAvatarProps) => {
  return (
    <Tooltip title="User Profile" placement="bottom-end" arrow>
      <IconButton
        onClick={onClick}
        sx={{ p: 0, height: '50px', width: '50px', borderRadius: '50%', cursor: 'pointer' }}
      >
        {avatarUrl ? (
          <img src={getAvatarUrl(avatarUrl)} alt="logo" width="50px" height="50px"></img>
        ) : (
          <StyledAccountCircle>
            <AccountCircle />
          </StyledAccountCircle>
        )}
      </IconButton>
    </Tooltip>
  )
}

export default UserProfileAvatar
