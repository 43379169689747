import React, { useState } from 'react'
import Moment from 'react-moment'
import styled from '@emotion/styled'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useSnackbar } from 'notistack'
import { useUpdateMediaRequest } from '../../hooks/useUpdateMediaRequest'
import { MediaItemDetails } from '../../types/MediaItemDetails'
import MediaRequestStatuses from '../../types/MediaRequestStatuses'
import RequestButtonType from '../../types/RequestButtonType'
import { getPosterUrl } from '../../utils/images'
import RequestStatusContainer from '../requestStatus/RequestStatusContainer'
import AdminRequestActions from './AdminRequestActions'

const StyledPoster = styled.img`
  border: solid 1px black;
  height: 232px;
  width: 152px;

  @media (max-width: 768px) {
    height: 123px;
    width: 81px;
  }

  @media (max-width: 500px) {
    height: 85px;
    width: 53px;
  }

  @media (max-width: 320px) {
    height: 61px;
    width: 40px;
  }
`

const StyledTableCell = styled(TableCell)`
  align: left;
  width: 20%;
`

const StyledTablePosterCell = styled(TableCell)`
  padding: 0;
  width: 15%;
`

const PosterContainer = styled.div`
  overflow: hidden;
  text-align: center;
  padding: 20px;
  margin-right: 5px;
`

const StyledTableCellHeader = styled(TableCell)`
  align: center;
  width: 15%;
`

interface AdminRequestsTableProps {
  mediaItems: MediaItemDetails[] | undefined
  isLoading: boolean
  onMediaRequestUpdated: () => void
}

const AdminRequestsTable = ({ mediaItems, isLoading, onMediaRequestUpdated }: AdminRequestsTableProps) => {
  const theme = useTheme()
  const mobileDevices = useMediaQuery(theme.breakpoints.down('md'))
  const skeletonItems = mobileDevices ? 3 : 6
  const { enqueueSnackbar } = useSnackbar()
  const [isUpdating, setIsUpdating] = useState(false)

  const onUpdateError = (message: string) => {
    setTimeout(() => {
      setIsUpdating(false)
      enqueueSnackbar(message, { variant: 'error' })
    }, 1000)
  }

  const onUpdateSuccess = () => {
    setTimeout(() => {
      setIsUpdating(false)
      enqueueSnackbar(`Successfully updated`, { variant: 'success' })
      onMediaRequestUpdated()
    }, 1000)
  }

  const updateMediaRequestMutation = useUpdateMediaRequest({
    onSuccess: onUpdateSuccess,
    onError: onUpdateError
  })

  if (mediaItems === undefined || isLoading) {
    return (
      <Grid container flexDirection="column" wrap="nowrap" sx={{ marginLeft: '10px' }}>
        {Array.from(Array(skeletonItems)).map((item, index) => (
          <Box key={index} sx={{ marginRight: '20px', my: 5 }}>
            <Skeleton variant="rectangular" width="100%" height={240} />
          </Box>
        ))}
      </Grid>
    )
  }

  const handleApprove = (mediaItem: MediaItemDetails) => {
    setIsUpdating(true)
    updateMediaRequestMutation.mutate({
      id: mediaItem.id,
      newStatus: MediaRequestStatuses.Approved
    })
  }

  const handleReject = (mediaItem: MediaItemDetails) => {
    setIsUpdating(true)
    updateMediaRequestMutation.mutate({
      id: mediaItem.id,
      newStatus: MediaRequestStatuses.Rejected
    })
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} stickyHeader={true}>
        <TableHead>
          <TableRow>
            <StyledTableCellHeader>Poster</StyledTableCellHeader>
            <StyledTableCellHeader>Title</StyledTableCellHeader>
            <StyledTableCellHeader>Requested By</StyledTableCellHeader>
            <StyledTableCellHeader>Requested Date</StyledTableCellHeader>
            <StyledTableCellHeader>Type</StyledTableCellHeader>
            <StyledTableCellHeader>Status</StyledTableCellHeader>
            <StyledTableCellHeader>Actions</StyledTableCellHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {mediaItems.map((mediaItem) => (
            <TableRow key={mediaItem.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <StyledTablePosterCell>
                <PosterContainer>
                  <StyledPoster
                    src={getPosterUrl('200', mediaItem.mediaType, mediaItem.posterPath)}
                    alt={`${mediaItem.title} Poster`}
                  ></StyledPoster>
                </PosterContainer>
              </StyledTablePosterCell>
              <StyledTableCell>{mediaItem.title}</StyledTableCell>
              <StyledTableCell>{mediaItem.requestedBy}</StyledTableCell>
              <StyledTableCell>
                <Moment date={mediaItem.requestedOn} format="DD-MMM-YYYY" />
              </StyledTableCell>
              <StyledTableCell>{mediaItem.mediaType}</StyledTableCell>
              <StyledTableCell>
                <RequestStatusContainer mediaItem={mediaItem} buttonType={RequestButtonType.ICONBUTTON} />
              </StyledTableCell>
              <StyledTableCell>
                <AdminRequestActions
                  onApprove={() => handleApprove(mediaItem)}
                  onReject={() => handleReject(mediaItem)}
                  isUpdating={isUpdating}
                />
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default AdminRequestsTable
