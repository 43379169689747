import React from 'react'
import styled from '@emotion/styled'
import Backdrop from '@mui/material/Backdrop'
import { getImageUrl } from '../../utils/images'

const StyledFerretImage = styled.img`
  width: 250px;
  height: 250px;
`
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

const Ferrets = ['Porka', 'Cami', 'Bandit', 'Boots', 'Rummy']

function getRandomFerret(): string {
  const randomIndex = Math.floor(Math.random() * Ferrets.length)
  return Ferrets[randomIndex]
}

const LoadingFerret = () => {
  const [ferret, setFerret] = React.useState<string>('')

  if (ferret === '') {
    setFerret(getRandomFerret())
  } else {
    setTimeout(() => {
      setFerret(getRandomFerret())
    }, 5000)
  }

  return (
    <Backdrop open={true}>
      <LoadingContainer>
        <StyledFerretImage src={getImageUrl(`${ferret}_Loading_250x250.gif`)} alt="Ferret Loading"></StyledFerretImage>
      </LoadingContainer>
    </Backdrop>
  )
}

export default LoadingFerret
