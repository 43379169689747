const colors = {
  //Confirmed colors
  plex: {
    100: '#D58915',
    80: 'rgba(213, 137, 21, 0.80)'
  },
  homePageTitles: '#B4C0D1',
  searchGridPosterTitle: '#B4C0D1',
  loginContainerColor: 'rgba(0, 0, 0, 0.65)',
  buttons: {
    hoverBackground: '#BC7000',
    focusBackground: '#EFA32F',
    activeBackground: '#AF6300',
    disabledBackground: '#EDD0A1',
    disabledText: '#D58915'
  },
  plexAvailability: {
    online: '#01AB32',
    offline: '#DC3545',
    maintenance: '#E2AF00'
  },
  requestStatuses: {
    requested: '#E89B55',
    approved: '#E5D011',
    rejected: '#C0463E',
    available: '#4B9020',
    background: 'rgba(79, 79, 79, 0.8)'
  },
  black: '#000000',
  white: '#FFFFFF',
  info: '#073DC9',
  warning: '#FFC107',
  success: '#198754',
  error: '#DC3545',
  sliderBackground: `rgba(0,0,0, 0.5)`,
  chip: {
    text: '#333333',
    icon: '#333333',
    hoverBackground: '#BC7000',
    focusBackground: '#EFA32F',
    activeBackground: '#AF6300',
    disabledBackground: '#F3DCB9',
    disabledText: '#E6BB77'
  },
  table: {
    header: '#D8952C',
    headerTextColor: '#333333',
    textRow: '#333333',
    background: 'rgba(79, 79, 79, 0.8)',
    backgroundAlt: '#545454',
    highlight: '#EAC48A'
  },
  modal: {
    background: '#D9D9D9'
  },
  //Unconfirmed colors
  test: '#E0D6F4',
  background: '#313437',
  header: '#4F4F4F',
  containerBody: `rgba(79, 79, 79, 0.25)`,
  textHeading: '#FFFFFF',
  textStandard: '#EEEEEE',
  inputText: '#313437',
  pageHover: '#1851E1',
  inactivePageNumber: '#959595',
  menuPanelBackground: '#45484B',
  reportIssue: '#C0463E'
}

export default colors
