import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import Typography from '@mui/material/Typography'
import routes from '../routes'
import { getDirectoryImageUrl, getImageUrl } from '../utils/images'
import LoadingButton from './loading/LoadingButton'

const WorkerFerretContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  height: 100vh;

  @media (max-width: 1024px) {
    height: 100vh;
  }

  @media (max-width: 896px) {
    height: 100%;
  }

  @media (max-width: 820px) {
    height: 100vh;
  }

  @media (max-width: 768px) {
    height: 100vh;
  }

  @media (max-width: 667px) {
    height: 100%;
  }

  Tablet and smaller @media (max-width: 414px) {
    height: 100vh;
  }
`

const StyledWorkerFerretImage = styled.img`
  width: 275px;
  height: 225px;
  margin-left: 60px;
`

const StyledDescriptionText = styled(Typography)`
  margin-bottom: 25px;
  width: 50%;
  padding-left: 10px;
  padding-right: 10px;

  //Tablet and smaller
  @media (max-width: 1024px) {
    padding-top: 10px;
    width: 75%;
  }
`

const WorkerFerret = () => {
  const navigate = useNavigate()

  const handleHomeClicked = () => {
    navigate(routes.root)
  }

  return (
    <WorkerFerretContainer>
      <Typography variant="h1" sx={{ marginBottom: '25px' }}>
        Ooops...
      </Typography>
      <StyledDescriptionText variant="body2">
        Somethings not working, the ferrets are working hard to fix it. Until then let's go back to the home page and
        start again.
      </StyledDescriptionText>
      <div>
        <StyledWorkerFerretImage
          src={getDirectoryImageUrl('ErrorGifs', 'Worker_Boots.gif')}
          alt="Not Found Indicator"
        ></StyledWorkerFerretImage>
      </div>
      <div style={{ marginBottom: '25px' }}>
        <LoadingButton sx={{ marginTop: '25px' }} id="go-home" onClick={handleHomeClicked}>
          Home Page
        </LoadingButton>
      </div>
    </WorkerFerretContainer>
  )
}

export default WorkerFerret
