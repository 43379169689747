const content = {
  pages: {
    login: {
      title: 'Sign In',
      tooltip: 'Please login with your ‘Plex’ account',
      rememberMe: 'Remember me',
      forgotPassword: 'Forgot password?',
      forgotPasswordLink: 'https://app.plex.tv/auth/#?resetPassword',
      submitButtonTitle: 'Sign In',
      usernamePlaceholder: 'Username',
      passwordPlaceholder: 'Password',
      errors: {
        loginFailed: 'Sorry, we were unable to sign you in. Please check your username and password and try again.',
        username: {
          required: 'Please enter your username',
          minLength: 'Please enter your username with a minimum of 5 characters.',
          maxLength: 'Please enter username with a maximum of 150 characters.'
        },
        password: {
          required: 'Please enter your password',
          minLength: 'Please enter your password with a minimum of 5 characters.',
          maxLength: 'Please enter password with a maximum of 150 characters.'
        }
      }
    },
    profile: {
      title: 'Profile',
      image: {
        imageAlt: 'Profile Image'
      },
      username: {
        title: 'Username'
      },
      email: {
        title: 'Email'
      },
      notifications: {
        title: 'Notifications',
        description: 'Select when to receive e-mail notifications for your requests.'
      },
      plexProfile: {
        title: 'Plex Profile Link',
        link: 'https://app.plex.tv/desktop/#!/profile'
      },
      defaultValue: 'N/A'
    },
    home: {
      search: {
        placeholder: 'Search for a Movie or TV Show',
        factOfTheDayPrefix: 'Ferret Fact of the Day:',
        errors: {
          searchTerm: {
            required: 'Please enter a value to search for'
          }
        }
      }
    },
    requests: {
      title: 'Custom Request',
      submitButtonTitle: 'Submit',
      fields: {
        title: {
          title: 'Title',
          placeholder: 'Title',
          erorrs: {
            required: 'Please enter a title',
            minLength: 'Please enter a title with a minimum of 1 character.',
            maxLength: 'Please enter a title with a maximum of 150 characters.'
          }
        },
        year: {
          title: 'Year',
          placeholder: 'Year (Optional)',
          erorrs: {
            //TODO: Add validation for year
          }
        },
        comment: {
          title: 'Comment',
          placeholder: 'Comment (Optional)'
        }
      }
    },
    admin: {
      title: 'Admin'
    }
  }
}

export default content
